<template>
  <div
    class="app-content content"
    :class="[{ 'show-overlay': $store.state.app.shallShowOverlay }, $route.meta.contentClass]"
  >
    <b-alert
      v-if="!isDashboard && !user_complete && !show"
      :show="!isDashboard && !user_complete"
      variant="danger"
      class="p-2"
    >
      <div v-if="$store.state.auth.user.isActive && $store.state.auth.user.isActive != '1'">
        <h2>{{ $t('user_no_active.register_success_confirm_email') }}</h2>
        <p>{{ $t('user_no_active.description1') }}</p>
        <hr>
        <p class="mb-2">
          {{ $t('user_no_active.email_process') }} {{ $t('user_no_active.email_resend') }}
        </p>
        <b-button
          :disabled="sendMaliLoading"
          variant="success"
          @click="resendActivationEmail()"
        >
          {{ $t('resendActivationEmail') }}
        </b-button>
      </div>
      <div v-else>
        <h3>{{ $t(`${cardInfo.nextStep.header }`) }}</h3>
        <hr v-if="!isInHiddenRoute">
        <p
          v-if="!isInHiddenRoute"
          :class="{'mb-2':!isInHiddenRoute}"
        >
          {{ $t(`${ cardInfo.nextStep.description }`) }}
        </p>
        <b-button
          v-if="!isInHiddenRoute"
          :to="`/${$route.params.lang}/${cardInfo.nextStep.btn_link}`"
        >

          {{ $t(`${cardInfo.nextStep.btn_text }`) }}
        </b-button>
      </div>
    </b-alert>
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BButton,
  BAlert,
} from 'bootstrap-vue'

import AppBreadcrumb from './AppBreadcrumb.vue'
import appFlow from '@/mixins/appFlow'
import ProfileApis from '@/services/apis/profile'

const profile = new ProfileApis()

export default {
  components: {
    BButton,
    AppBreadcrumb,
    BAlert,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
  mixins: [appFlow],
  data() {
    return {
      errorShow: false,
      sendMaliLoading: false,
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    isDashboard() {
      return this.$route.name === 'dashboard'
    },
    isActive() {
      return this.$store.state.auth.user.isActive
    },
    isInHiddenRoute() {
      if (this.cardInfo.nextStep.hiddenRoutes) {
        return this.cardInfo.nextStep.hiddenRoutes.some(item => this.$route.fullPath.substring(3).indexOf(item) !== -1)
      }
      return false
    },
  },
  watch: {
    lang(val, old) {
      const exchangeElement = document.querySelector(`a[href='${`${process.env.VUE_APP_EXCHANGE_ADDRESS}/${old}/pendingOrder`}']`)
      if (exchangeElement) {
        exchangeElement.href = `${process.env.VUE_APP_EXCHANGE_ADDRESS}/${val}/pendingOrder`
      }
    },
    isActive() {
      this.exchangeLinkAction()
    },
  },
  mounted() {
    const exchangeElement = document.querySelector(`a[href='${`${process.env.VUE_APP_EXCHANGE_ADDRESS}/pendingOrder`}']`)
    if (exchangeElement) {
      exchangeElement.target = '_self'
      exchangeElement.href = `${process.env.VUE_APP_EXCHANGE_ADDRESS}/${this.$i18n.locale}/pendingOrder`
    }
    this.exchangeLinkAction()
  },

  methods: {
    resendActivationEmail() {
      this.sendMaliLoading = true
      profile.reSendActivationMail({ email: this.$store.state.auth.user.email }).then(() => {
        this.$swal({
          title: this.$t('activationEmailSended'),
          icon: 'success',
          timer: 2500,
          showConfirmButton: true,
        })
      }).finally(() => {
        this.sendMaliLoading = false
      })
    },

    exchangeLinkAction() {
      const exchangeElement = document.querySelector(`a[href='${process.env.VUE_APP_EXCHANGE_ADDRESS}/${this.$i18n.locale}/pendingOrder']`)
      if (exchangeElement) {
        if (this.isActive === '0') {
          exchangeElement.onclick = () => {
            this.$swal({
              title: this.$t('exchange_menu_click_user_no_active'),
              icon: 'warning',
              timer: 3000,
              showConfirmButton: false,
            })
            return false
          }
          exchangeElement.classList.add('disabled-link')
        } else {
          exchangeElement.removeAttribute('onclick')
          exchangeElement.classList.remove('disabled-link')
        }
      }
    },
  },
}
</script>
