const translatePrefix = 'dashboard_page'

const appFlow = {
  deposit: {
    id: 1,
    prevStep: {
      header: `${translatePrefix}.success_document`,
    },
    nextStep: {
      header: `${translatePrefix}.deposit_message`,
      description: `${translatePrefix}.go_to_deposit`,
      btn_text: `${translatePrefix}.deposit`,
      btn_link: 'customer-wallets/deposit',
      hiddenRoutes: ['/customer-wallets/deposit',
        '/customer-wallets/fiat/deposit',
        '/crypto-wallets',
        '/customer-wallets/CreateCryptoWallet',
        '/customer-wallets/crypto-deposit',
      ],
    },
  },
  profile_complete: {
    id: 2,
    prevStep: {
      header: `${translatePrefix}.success_confirm_email`,
    },
    nextStep: {
      header: `${translatePrefix}.use_exchange_platform`,
      description: `${translatePrefix}.complete_profile_desc`,
      btn_text: `${translatePrefix}.profile-btn`,
      btn_link: 'profile/0',
      hiddenRoutes: ['/profile/0'],
    },
  },
  general: {
    id: 3,
    prevStep: {
      header: `${translatePrefix}.complete_profile`,
    },
    nextStep: {
      header: `${translatePrefix}.upload-identification-docs`,
      description: `${translatePrefix}.complete-document-desc`,
      btn_text: `${translatePrefix}.document-btn`,
      btn_link: 'document/0',
      hiddenRoutes: ['/document/0'],
    },
  },
  bank: {
    id: 4,
    prevStep: {
      header: `${translatePrefix}.success_document`,
    },
    nextStep: {
      header: `${translatePrefix}.to_withdraw`,
      description: `${translatePrefix}.upload-docs`,
      btn_text: `${translatePrefix}.bank-document-btn`,
      btn_link: 'document/1',
      hiddenRoutes: ['/document/1'],
    },
  },
  legal: {
    id: 5,
    prevStep: {
      header: `${translatePrefix}.complete_profile`,
    },
    nextStep: {
      header: `${translatePrefix}.legal_document`,
      description: `${translatePrefix}.upload-legal`,
      btn_text: `${translatePrefix}.upload_legal_btn`,
      btn_link: 'document/2',
      hiddenRoutes: ['/document/2'],
    },
  },
}

export default {
  data() {
    return {
      show: true,
      isUserActive: false,
      isDeposit: true,
      user_complete: false,
      cardInfo: {
        id: '',
        prevStep: {
          header: '',
        },
        nextStep: {
          header: '',
          description: '',
          btn_text: '',
          btn_link: '',
        },
      },
    }
  },

  computed: {
    isProfileExist() {
      return `
        ${this.$store.state.profile.profileInfo.email}|
        ${this.$store.state.profile.profileInfo.isActive}|
        ${this.$store.state.profile.profileInfo.is_deposited}|
        ${this.$store.state.profile.profileInfo.profile_complete}|
        ${this.$store.state.profile.profileInfo.mobile_is_verify}|
        ${this.$store.state.profile.profileInfo.document?.general?.exist}|
        ${this.$store.state.profile.profileInfo.document?.legal?.exist}
      `
      // ${this.$store.state.profile.profileInfo?.document?.bank?.exist}|
    },
  },

  watch: {
    isProfileExist(val) {
      if (val) {
        this.show = false
        this.setFlow()
      }
    },
  },

  async mounted() {
    if (this.$store.state.profile.profileInfo.isActive) {
      this.show = false
      this.setFlow()
    }
  },

  methods: {
    setFlow() {
      // this.show = true
      if (this.$store.state.profile.profileInfo.isActive === '1') {
        this.isUserActive = true
        if (!this.$store.state.profile.profileInfo.mobile_is_verify
          || !this.$store.state.profile.profileInfo.profile_complete) {
          this.cardInfo = appFlow.profile_complete
        } else if (!this.$store.state.profile.profileInfo.document.general.exist) {
          this.cardInfo = appFlow.general
        } else if (this.$store.state.profile.profileInfo.is_legal && !this.$store.state.profile.profileInfo.document.legal.exist) {
          this.cardInfo = appFlow.legal
        } else if (!this.$store.state.profile.profileInfo.is_deposited) {
          this.cardInfo = appFlow.deposit
        } else {
          this.user_complete = true
        }
        // else if (!this.$store.state.profile.profileInfo.document.bank.exist) {
        //   this.cardInfo = appFlow.bank
        // }
      } else {
        this.isUserActive = false
      }

      // this.show = false
    },
  },
}
